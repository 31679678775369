<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <slot
        name="activator"
        v-on="on"
      >
        <template v-if="button">
          <v-btn
            class="text-none"
            text
            :dark="darkButton"
            v-on="on"
          >
            <v-icon left>
              mdi-account-circle-outline
            </v-icon> {{ user.first_name }}
          </v-btn>
        </template>

        <template v-else>
          <v-list-item
            v-on="on"
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.first_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ currentSchool.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </slot>
    </template>

    <v-list>
      <v-list-item
        :to="{name: 'userProfile'}"
      >
        <v-list-item-title>
          {{ $t('user.profile') }}
        </v-list-item-title>
      </v-list-item>

      <v-divider />

      <v-list-item>
        <language-switch
          v-if="user"
          v-model="user.language"
          @change="saveCurrentUser"
          tile
        />
      </v-list-item>

      <v-divider />

      <v-list-item
        :href="api.legacy.logout()"
      >
        <v-list-item-title>
          {{ $t('user.logout') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import api from "./../../api"
import { mapActions } from "vuex"
import LanguageSwitch from "./language_switch.vue"

export default {
  name: "UserMenu",
  components: { LanguageSwitch },
  props: {
    user: Object,
    student: { type: Boolean, default: false },
    dark: { type: Boolean },
    button: { type: Boolean, default: true },
  },
  computed: {
    api() { return api },
    darkButton() {return !this.student && this.dark === true},
  },
  methods: {
    ...mapActions(["updateCurrentUser", "currentSchool"]),
    saveCurrentUser() {
      this.updateCurrentUser(this.user)
    },
  },
}
</script>

<style scoped>

</style>
